export const useSetTablePagination = (
  array: any[],
  options: {
    page: number;
    perPage: number;
  }
) => {
  const { page, perPage } = options;

  const start = (page - 1) * perPage;
  const end = start + perPage;

  return array.slice(start, end);
};
